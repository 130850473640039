<template>

  <div>

    <v-row class="mt-0" v-if="loaded_values && loaded_values.channel_subscription">
      <v-col cols="8">
        <p class="primary--text">{{$t('PUBLICATION.FIELD_PRICING_MODEL')}}<small class="error--text ml-2" v-if="!method||method.length === 0">{{$t('PUBLICATION.FIELD_PRICING_MODEL_ERROR')}}</small></p>

        <v-select
         v-model="method"
         :rules="[$rules.required]"
         style="display:none"
        />
        <div class="ma-0 d-flex" v-if="loaded_values.channel_subscription.methods.indexOf('organic') !== -1">
          <v-col class="pa-0" cols="3">
            <v-checkbox class="mt-1"
            v-model="method"
            value="organic"
            label="Organic"
            />
          </v-col>
          <v-col cols="6"></v-col>
          <v-col class="pa-0" cols="3">
            <v-chip outlined
            class="mt-1"
            color="grey"
            label>
            {{$t('COMMON.FREE')}}
          </v-chip>
          </v-col>
          <v-col class="pl-2 pa-0" cols="1" v-if="method === 'organic'">
            <v-input type="hidden"
            v-model="price"
            :value="0"
            />
          </v-col>
        </div>
        <div class="ma-0 d-flex" v-if="loaded_values.channel_subscription.methods.indexOf('credit') !== -1">
          <v-col class="pa-0" cols="3">
            <v-checkbox class="mt-1"
            v-model="method"
            value="credit"
            label="Crédits"
            />
          </v-col>
          <v-col cols="6"></v-col>
          <v-col class="pa-0" cols="3">
            <v-chip outlined class="mt-1" color="grey" label>{{$t('COMMON.FIXED')}}</v-chip>
          </v-col>
          <v-col class="pl-2 pa-0" cols="1" v-if="method === 'credit'">
            <v-input type="hidden"
            v-model="price"
            :value="1"
            />
          </v-col>
        </div>
        <div class="ma-0 d-flex" v-if="loaded_values.channel_subscription.methods.indexOf('cpc') !== -1">
          <v-col class="pa-0" cols="3">
            <v-checkbox class="mt-1"
            v-model="method"
            value="cpc"
            label="CPC"
            />
          </v-col>
          <v-col cols="6"></v-col>
          <template v-if="method === 'cpc'" >
            <v-col class="pa-0" cols="3">
              <v-text-field
              :autofocus="true"
              v-model="price"
              :label="$t('COMMON.PRICE')"
              type="number"
              step="0.01"
              min="0.01"
              :rules="[$rules.required, $rules.min(0.01)]"
              outlined
              dense
              hide-details
              />
            </v-col>
            <v-col class="pl-2 pa-0" cols="1">
              <v-btn icon @click="price = loaded_values.channel_subscription.prices.cpc">
                <v-icon>mdi-backup-restore</v-icon>
              </v-btn>
            </v-col>
          </template>
        </div>
        <div class="ma-0 d-flex" v-if="loaded_values.channel_subscription.methods.indexOf('cpm') !== -1">
          <v-col class="pa-0" cols="3">
            <v-checkbox class="mt-1"
            v-model="method"
            value="cpm"
            label="CPM"
            />
          </v-col>
          <template v-if="method === 'cpm'">
            <v-col class="pa-0" cols="3">
              <v-text-field
                :autofocus="true"
                v-model="price"
                :label="$t('COMMON.PRICE')"
                type="number"
                step="0.01"
                min="0.01"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col class="pl-2 pa-0" cols="1">
              <v-btn icon @click="price = loaded_values.channel_subscription.channel_subscription.prices.cpm">
                <v-icon>mdi-backup-restore</v-icon>
              </v-btn>
            </v-col>
          </template>
        </div>
        <div class="ma-0 d-flex" v-if="loaded_values.channel_subscription.methods.indexOf('cpa') !== -1">
          <v-col class="pa-0" cols="3">
            <v-checkbox class="mt-1"
            v-model="method"
            value="cpa"
            label="CPA"
            />
          </v-col>
          <v-col cols="6"></v-col>
          <template v-if="method === 'cpa'">
            <v-col class="pa-0" cols="3" >
              <v-text-field
                :autofocus="true"
                v-model="price"
                :label="$t('COMMON.PRICE')"
                type="number"
                step="0.01"
                min="0.01"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col class="pl-2 pa-0" cols="1">
              <v-btn icon @click="price = loaded_values.channel_subscription.prices.cpa">
                <v-icon>mdi-backup-restore</v-icon>
              </v-btn>
            </v-col>
          </template>
        </div>
      </v-col>
    </v-row>

  </div>

</template>

<script>
export default {
  name:"FormPublicationCompactPrice",
  data: () => ({
    //
    price: 0,
    method: undefined,
  }),
  props: {
    values: {
      type: [Object, undefined],
      default: () => ({})
    },
    loaded_values: {
      type: [Object, undefined],
      default: () => ({})
    },
  },
  watch: {
    price (n) {
      this.values.price = n
    },
    method (n) {
      this.values.method = n
    },
    values: {
      handler () {
        this.attribValues()
      },
      deep: true,
    }
  },
  created () {
    this.attribValues()
  },
  methods: {
    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        this[key] = values[key]
      })
    }
  }
}
</script>
